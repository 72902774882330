  /* ========================================================================
   * DOM-based Routing
   * Based on http://goo.gl/EUTi53 by Paul Irish
   *
   * Only fires on body classes that match. If a body class contains a dash,
   * replace the dash with an underscore when adding it to the object below.
   *
   * .noConflict()
   * The routing is enclosed within an anonymous function so that you can
   * always reference jQuery with $, even when in .noConflict() mode.
   * ======================================================================== */

  (function ($) {

  	// Use this variable to set up the common and page specific functions. If you
  	// rename this variable, you will also need to rename the namespace below.
  	var Sage = {
  		// All pages
  		'common': {
  			init: function () {
  				// JavaScript to be fired on all pages
  				$(document).ready(function () {
  					var halfHero = function () {
  						var hhoffset = $('.half-hero-offset').length;
  						var hhoffsetR = $('.half-hero-offset-r').length;
  						if (hhoffset > 0 || hhoffsetR > 0) {
  							var brWidth = $(window).width();
  							var container = $('.container').width();
  							var margin = brWidth - container;
  							if (margin > 0) {
  								var offset = margin / 2;
  								$('.half-hero-offset').css('padding-left', offset);
  								$('.half-hero-offset-r').css('padding-right', offset);
  							}
  						}
  					};
  					halfHero();
  					var dropDown = function () {
  						var brWidth = $(window).width();
  						if (brWidth < 1200) {
  							$('#home-drop').removeClass('dropdown');
  							$('#home-drop').addClass('dropup');
  						}
  					};
  					dropDown();
  					$(window).resize(function () {
  						halfHero();
  						dropDown();
  					});
  					////////////////////////
  					var teamLoop = function () {
  						$('.home-about-us-image').animate({
  							'background-position': '100%'
  						}, 25000, 'linear', teamLoopReset);
  					};

  					var teamLoopReset = function () {
  						$('.home-about-us-image').animate({
  							'background-position': '0%'
  						}, 25000, 'linear', teamLoop);
  					};
  					teamLoop();
  					////////////////////////
  					var searchWidth = $('#search-form-wrap').width();
  					$('#search-form-wrap .search-field').css('width', searchWidth - 120);
  					$('.close-search').click(function () {
  						var searchClose = function () {
  							$('#search-form-wrap').animate({
  								minHeight: '0'
  							});
  						};
  						$('#search-form-wrap .col-12').fadeOut(searchClose);
  						$(this).hide();
  						$('.open-search').fadeIn();
  					});

  					$('.open-search').click(function () {
  						var searchOpen = function () {
  							$('#search-form-wrap .col-12').fadeIn();
  						};
  						$('#search-form-wrap').animate({
  							minHeight: '140'
  						}, searchOpen);
  						$(this).hide();
  						$('.close-search').fadeIn();
  						$('.sub-menu').fadeOut();
  						$('.sub-menu-bkg').fadeOut();
  						$('.menu-item-has-children').removeClass('green');
  					});
  					///////////////////////////////////////////////////////
  					var navOpen = function () {
  						$('.menu-item-has-children').removeClass('green');
  						$('.sub-menu').fadeOut();
  						$(this).children('.sub-menu').css("display", "flex").hide().fadeIn();
  						$(this).addClass('green');
  						$('.sub-menu-bkg').fadeIn(200);
  						$('#search-form-wrap').animate({
  							minHeight: '0'
  						});
  						$('#search-form-wrap .col-12').fadeOut();
  						$('.close-search').hide();
  						$('.open-search').fadeIn();
  					};

  					var navClose = function () {
  						$('.banner, .sub-menu-wrap').hoverIntent(function () {
  							//do nothing
  						}, function () {
  							$('.sub-menu').fadeOut();
  							$('.sub-menu-bkg').fadeOut(200);
  							$('.menu-item-has-children').removeClass('green');
  						});
  					};

  					$('.nav-primary .menu-item-has-children').hoverIntent({
  						over: navOpen,
  						out: navClose,
  						timeout: 400
  					});
  					///////////////////////////////////////////////////////
  					//var brWidth = $(window).width();
  					//$('#cta-block').css('margin-right', -brWidth);
  					var block = $('#cta-block').length;
  					if (block > 0) {
  						$(window).scroll(function () {
  							var hT = $('#cta-block').offset().top,
  								hH = $('#cta-block').outerHeight(),
  								wH = $(window).height(),
  								wS = $(this).scrollTop();
  							if (wS > (hT + hH - wH)) {
  								$('#cta-block').animate({
  									opacity: 1
  								}, 900);
  								//$('#cta-block').animate({marginRight: 0}, 1200);
  							}
  						});
  					}
  					///////////////////////////////////////////////////////
  					var hhBlock = $('.scroll-transform').length;
  					console.log(hhBlock);
  					if (hhBlock > 0) {
  						$(window).scroll(function () {

  							/* Check the location of each desired element */
  							$('.scroll-transform').each(function (i) {

  								var bottom_of_object = $(this).offset().top + $(this).outerHeight();
  								var bottom_of_window = $(window).scrollTop() + $(window).height();

  								/* If the object is completely visible in the window, fade it it */
  								if (bottom_of_window > bottom_of_object) {

  									$(this).removeClass('scroll-transform');
  								}
  							});
  						});
  					}
  					///////////////////////////////////////////////////////
  					//var cover = function(){$('#half-hero-block .half-hero-bkg-img').css('background-size', 'cover')};
  					//$('#half-hero-block .half-hero-bkg-img').animate({backgroundSize: '100%'}, 900, cover);
  					$('#half-hero-block .half-hero-bkg-img').removeClass('half-hero-transform');
  					///////////////////////////////////////////////////////
  					/*
  					$('.nav-primary .menu-item-has-children').click(function(){
  					  if ( $(this).hasClass('green') ) {
  						$('.sub-menu').fadeOut();
  						$('.sub-menu-bkg').fadeOut();
  						$('.menu-item-has-children').removeClass('green');
  						  } else {
  						$(this).children('.sub-menu').css("display", "flex").hide().fadeIn();
  						$(this).addClass('green');
  						$('.sub-menu-bkg').fadeIn();
  						$('#search-form-wrap').animate({minHeight: '0'});
  						$('#search-form-wrap .col-12').fadeOut();
  						$('.close-search').hide();
  						$('.open-search').fadeIn();
  					  }
  					});
  					*/

  					$('.main').click(function () {
  						$('.sub-menu').fadeOut();
  						$('.sub-menu-bkg').fadeOut();
  						$('.menu-item-has-children').removeClass('green');
  					});

  					$('.open-inner-content').click(function () {
  						var tid = $(this).attr('data');
  						$(tid + ' .team-content-inner').fadeIn();
  						$(tid + ' .inner-img').fadeIn();
  						$(tid + ' .close-inner').fadeIn();
  						$(tid).animate({
  							height: '100%',
  							width: '100%'
  						}, 500);
  					});
  					$('.close-inner').click(function () {
  						var tid = $(this).attr('data');
  						$(tid).animate({
  							height: '100%',
  							width: '0'
  						}, 500);
  						$(tid + ' .team-content-inner').fadeOut();
  						$(tid + ' .inner-img').fadeOut();
  						$(tid + ' .close-inner').fadeOut();
  					});

  					$('.open-nav').click(function () {
  						if ($(this).hasClass('open')) {
  							$('.nav-primary-mobile').fadeOut();
  							$(this).removeClass('open');
  							$(this).removeAttr('close');
  							$(this).attr('name', 'menu');
  						} else {
  							$('.nav-primary-mobile').fadeIn();
  							$(this).addClass('open');
  							$(this).removeAttr('name');
  							$(this).attr('name', 'close');
  							$('#search-form-wrap').animate({
  								minHeight: '0'
  							});
  							$('#search-form-wrap .col-12').fadeOut();
  							$('.close-search').hide();
  							$('.open-search').fadeIn();
  						}
  					});

  					$('.nav-primary-mobile .menu-item-has-children').click(function (e) {
  						if ($(this).hasClass('green')) {
  							$('.sub-menu').fadeOut();
  							$('.sub-menu-bkg').fadeOut();
  							$('.menu-item-has-children').removeClass('green');
  						} else {
  							$(this).children('.sub-menu').fadeIn();
  							$(this).addClass('green');
  							$('#search-form-wrap').animate({
  								minHeight: '0'
  							});
  							$('#search-form-wrap .col-12').fadeOut();
  							$('.close-search').hide();
  							$('.open-search').fadeIn();
  							e.preventDefault();
  						}
  					});




  					$('.resource-filter').click(function () {
  						var res = $(this).attr('data');
  						var resource = '.resources_tax-' + res;
  						$('.type-resources').show();
  						$('.type-resources').not(resource).fadeOut();
  						$('.sidebar-nav-item').removeClass('sidebar-nav-active');
  						$(this).addClass('sidebar-nav-active');
  					});
  					$('.resource-show-all').click(function () {
  						$('.sidebar-nav-item').removeClass('sidebar-nav-active');
  						$('.type-resources').show();
  					});
  					if ($('#resource-modal-form').length > 0) {
  						var url = $('#resource-url').val();
  						$('.resource-url input').val(url);
  					}

  					if ($('body.category, body.tax-resources_tax').length > 0) {
  						var cat = $('#cat').val();
  						console.log(cat);
  						$('.sidebar-nav-item[data="' + cat + '"]').addClass('sidebar-nav-active');
  					}

  				});
  			},
  			finalize: function () {
  				// JavaScript to be fired on all pages, after page specific JS is fired
  			}
  		},
  		// Home page
  		'home': {
  			init: function () {
  				// JavaScript to be fired on the home page
  				function isIE() {
  					var myNav = navigator.userAgent.toLowerCase();
  					return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
  				}

  				window.isIEOld = isIE() && isIE() < 9;
  				window.isiPad = navigator.userAgent.match(/iPad/i);

  				var img = $('.video').data('placeholder'),
  					video = $('.video').data('video'),
  					noVideo = $('.video').data('src'),
  					el = '';
  				//if($(window).width() > 512 && !isIEOld && !isiPad) {
  				if ($(window).width() > 767 && !isIEOld) {
  					el += '<video autoplay muted loop poster="' + img + '">';
  					el += '<source src="' + video + '" type="video/mp4">';
  					el += '</video>';
  				} else {
  					//el = '<div class="video-element" style="background-image: url(' + noVideo + ')"></div>';
  					$('.home-page-hero').css('background-image', 'url(' + noVideo + ')');
  				}

  				$('.video').prepend(el);

  				var hdhOver = function () {
  					$(this).addClass('hdh-over');
  				};
  				var hdhOut = function () {
  					$(this).removeClass('hdh-over');
  				};
  				$('.home-desk-hover').hoverIntent({
  					over: hdhOver,
  					out: hdhOut,
  					timeout: 200
  				});
  			},

  			finalize: function () {
  				// JavaScript to be fired on the home page, after the init JS

  			}
  		},
  		// About us page, note the change from about-us to about_us.
  		'about_us': {
  			init: function () {
  				// JavaScript to be fired on the about us page
  			}
  		}
  	};

  	// The routing fires all common scripts, followed by the page specific scripts.
  	// Add additional events for more control over timing e.g. a finalize event
  	var UTIL = {
  		fire: function (func, funcname, args) {
  			var fire;
  			var namespace = Sage;
  			funcname = (funcname === undefined) ? 'init' : funcname;
  			fire = func !== '';
  			fire = fire && namespace[func];
  			fire = fire && typeof namespace[func][funcname] === 'function';

  			if (fire) {
  				namespace[func][funcname](args);
  			}
  		},
  		loadEvents: function () {
  			// Fire common init JS
  			UTIL.fire('common');

  			// Fire page-specific init JS, and then finalize JS
  			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
  				UTIL.fire(classnm);
  				UTIL.fire(classnm, 'finalize');
  			});

  			// Fire common finalize JS
  			UTIL.fire('common', 'finalize');
  		}
  	};

  	// Load Events
  	$(document).ready(UTIL.loadEvents);

  })(jQuery); // Fully reference jQuery after this point.
